/* General styling for the entire app */
.App {
  font-family: 'Helvetica Neue', Arial, sans-serif; /* Modern, clean look */
  color: #333;
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Allow vertical scrolling */
  background-color: #f9f9f9; /* Very light grey */
}

/* Header styling for a professional look */
.App-header {
  height: 45px; /* Further reduced from 55px */
  background-color: #2C3E50; /* Dark, sophisticated blue */
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Maintain spacing for elements */
  font-size: 14px; /* Smaller text size for header */
  padding: 0 15px; /* Reduced padding for sleeker look */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow */
}


.secondary-header {
  height: 50px; /* Consistent with primary header reduction */
  background-color: #2C3E50;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center; /* Center content for a balanced appearance */
  font-size: 18px; /* Uniform smaller font size */
  padding: 0 15px; /* Matching reduced padding */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Improved navigation link styling for a professional appearance */
.navigation {
  display: flex;
  list-style: none;
}

.link {
  color: white;
  text-decoration: none;
  padding: 8px 15px;
  margin-right: 10px;
  border-radius: 15px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.link:hover, .link:focus {
  background-color: #1ABC9C;
  transform: scale(1.05);
}

/* Ensures the map and other content takes the remaining space effectively */
.map-container {
  flex-grow: 1;
  width: 100%;
  position: relative;
}

/* Styling for loading and error messages */
.loading, .error-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.15);
  text-align: center;
  width: 300px;
}

/* Styling for air quality and other information display */
.air-quality-info {
  background: white;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.15);
  text-align: center;
}

h2, p {
  margin: 10px 0;
  line-height: 1.6;
}

/* Responsive design adjustments for mobile */
@media (max-width: 600px) {
  .App-header, .secondary-header {
    font-size: 13px; /* Even smaller font size for mobile */
    height: 40px; /* More compact header on mobile */
  }

  .navigation {
    display: block;
  }

  .link {
    display: block;
    margin: 5px auto;
    width: 90%;
  }
}
