/* blog.css - Advanced CSS Module for Blog Component with Enhancements */

/* Base settings */
:root {
    --primary-color: #28527a;
    --secondary-color: #8ac4d0;
    --accent-color: #f4d160;
    --light-bg: #f7f7f7;
    --dark-bg: #2c3e50;
    --dark-text: #333333;
    --light-text: #ffffff;
    --font-family-main: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    --font-family-title: 'Georgia', Times, 'Times New Roman', serif;
}

/* Dark mode support */
[data-theme="dark"] {
    --primary-color: #3498db;
    --secondary-color: #2ecc71;
    --accent-color: #e74c3c;
    --light-bg: #34495e;
    --dark-bg: #2c3e50;
    --dark-text: #ecf0f1;
    --light-text: #bdc3c7;
}

/* General Layout */
.blogContainer {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    background-color: var(--light-bg);
    color: var(--dark-text);
    font-family: var(--font-family-main);
    transition: background-color 0.3s ease, color 0.3s ease;
    overflow-y: auto;  /* Ensures scrolling within the container */
    height: auto;  /* Adjust height to be auto to ensure it contains all children */
    min-height: 100vh;  /* Ensures it takes at least the full viewport height */
}

.postCard {
    background: var(--light-text);
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    margin-bottom: 30px;
    padding: 20px;
    box-shadow: 0 6px 12px rgba(0,0,0,0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.postCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0,0,0,0.08);
}

/* Typography */
h2, h3, h4, h5 {
    font-family: var(--font-family-title);
    color: var(--primary-color);
    transition: color 0.3s ease;
}

h2 {
    font-size: 26px;
    margin-bottom: 8px;
}

h3 {
    font-size: 22px;
    margin-bottom: 6px;
}

h4 {
    font-size: 18px;
    color: var(--secondary-color);
    margin-bottom: 4px;
}

h5 {
    font-size: 16px;
    font-weight: bold;
    color: var(--accent-color);
}

p {
    font-size: 14px;
    line-height: 1.7;
}

/* Links */
a {
    color: var(--primary-color);
    text-decoration: none;
    transition: color 0.3s, text-decoration 0.3s;
}

a:hover, a:focus {
    color: var(--secondary-color);
    text-decoration: underline;
}

/* Images */
img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
    object-fit: contain; /* Ensures images do not stretch out of their bounding boxes */
}

/* Buttons and Interactive Elements */
button {
    background-color: var(--primary-color);
    color: var(--light-text);
    border: none;
    padding: 12px 24px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

button:hover, button:focus {
    background-color: var(--accent-color);
    outline: none;
}

/* Error and Loading States */
.loading, .error {
    color: var(--accent-color);
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    padding: 20px;
}

/* Accessibility Enhancements */
[role="alert"] {
    background-color: #ffdddd;
    border-left: 4px solid #cc0000;
    padding: 10px;
    margin-bottom: 20px;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
    .blogContainer {
        padding: 15px;
    }

    h2 {
        font-size: 24px;
    }

    h3 {
        font-size: 20px;
    }
}

@media (max-width: 768px) {
    .blogContainer {
        padding: 10px;
    }

    h2, h3 {
        font-size: 18px;
    }

    h4, h5 {
        font-size: 16px;
    }
}
